import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _hslToHex3 from "hsl-to-hex";

var _hslToHex2 = "default" in _hslToHex3 ? _hslToHex3.default : _hslToHex3;

import * as _colorString3 from "color-string";

var _colorString2 = "default" in _colorString3 ? _colorString3.default : _colorString3;

var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _hslToHex = _interopRequireDefault(_hslToHex2);

var _colorString = _interopRequireDefault(_colorString2);

var isRgb = R.test(/rgb/g);
var isRgba = R.test(/rgba/g);
var isHsl = R.test(/hsl/g);
var isHsla = R.test(/hsla/g);
/**
 * Transform rgb color to hexa
 *
 * @param {String} styles value
 * @returns {Object} transformed value
 */

var parseRgb = R.compose(_colorString.default.to.hex, _colorString.default.get.rgb);
/**
 * Transform Hsl color to hexa
 *
 * @param {String} styles value
 * @returns {Object} transformed value
 */

var parseHsl = R.compose(R.toUpper, R.apply(_hslToHex.default), R.map(Math.round), _colorString.default.get.hsl);
/**
 * Transform given color to hexa
 *
 * @param {String} styles value
 * @returns {Object} transformed value
 */

var transformColor = function transformColor(value) {
  return R.cond([[isRgba, parseRgb], [isRgb, parseRgb], [isHsla, parseHsl], [isHsl, parseHsl], [R.T, R.always(value)]])(value);
};

var _default = transformColor;
exports.default = _default;
export default exports;
export const __esModule = exports.__esModule;